<template>
  <nav class="mdc-list pr-4">
    <mdc-button
      :disabled="$store.getters.verifiedAccounts.length === 0"
      ripple
      class="mdc-list-item w-full"
      data-qa="compose"
      @click.native="$router.push('/email/create')"
    >
      <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
        >edit</i
      >
      <span class="mdc-list-item__text">Compose</span>
    </mdc-button>

    <hr class="mdc-list-divider" />

    <input
      v-model="search"
      type="search"
      placeholder="Search Emails"
      ripple
      class="mdc-list-item w-full bg-gray-lighter"
      @input="setSearch"
      @keyup.enter="searchNow"
    />

    <mdc-button
      v-for="option in options"
      :key="option.view"
      :class="{
        'mdc-list-item--selected':
          $store.state.email.pagination.view === option.view,
      }"
      class="mdc-list-item w-full"
      ripple
      @click.native="setEmailView(option.view)"
    >
      <i
        class="material-icons mdc-list-item__graphic"
        aria-hidden="true"
        v-text="option.icon"
      />
      <span class="mdc-list-item__text" v-text="option.label" />
      <span
        v-if="$store.state.email.pagination.view === option.view"
        class="mdc-list-item__meta"
        v-text="`(${$store.state.email.total})`"
      />
    </mdc-button>

    <hr class="mdc-list-divider" />

    <mdc-button
      class="mdc-list-item w-full"
      ripple
      @click.native="$router.push('/email/logs')"
    >
      <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
        >list_alt</i
      >
      <span class="mdc-list-item__text">Logs</span>
    </mdc-button>

    <hr class="mdc-list-divider" />

    <mdc-button
      class="mdc-list-item w-full"
      ripple
      @click.native="$router.push('/email/contacts')"
    >
      <i class="material-icons mdc-list-item__graphic" aria-hidden="true"
        >contacts</i
      >
      <span class="mdc-list-item__text">Contacts / Groups</span>
      <span
        v-if="$store.state.contacts.total > 0"
        class="mdc-list-item__meta"
        v-text="`(${$store.state.contacts.total})`"
      />
    </mdc-button>
  </nav>
</template>

<script>
import debounce from 'debounce';
import MdcButton from '@/components/mdc/button.vue';

export default {
  components: {
    MdcButton,
  },

  data: () => ({
    options: [
      { label: 'All Emails', view: 'all', icon: 'email' },
      { label: 'Scheduled', view: 'scheduled', icon: 'alarm' },
      { label: 'Sent', view: 'sent', icon: 'check_circle' },
      { label: 'Recurring', view: 'recurring', icon: 'autorenew' },
      { label: 'Drafts', view: 'draft', icon: 'drafts' },
      { label: 'Trash', view: 'trash', icon: 'delete' },
    ],
    search: '',
  }),

  mounted() {
    this.setSearch = debounce(this.setSearch, 3000);
    this.$store.commit('email/setSearch', '');
  },

  methods: {
    setEmailView(view) {
      this.search = '';
      this.$store.commit('email/setView', view);
      if (this.$route.path !== '/email') this.$router.push('/email');
    },
    setSearch() {
      this.$store.commit('email/setSearch', this.search);
      if (this.search !== '' && this.$route.path !== '/email')
        this.$router.push('/email');
    },
    searchNow() {
      this.setSearch.flush();
    },
  },
};
</script>
